import { BreakpointObserver } from '@angular/cdk/layout';
import { OnInit, OnDestroy, Component } from '@angular/core';
import {
  BaseLayoutService,
  UserMenuItem,
} from '@devop/ngx-metronic/base-layout';
import { BehaviorSubject, combineLatest, map, Subject, takeUntil } from 'rxjs';
import { AuthService } from '../core/auth.service';
import { LocalStorageService } from '../core/local-storage.service';
import { BSBreakpoints, untilDestroyed } from '../shared/utils';

@Component({
  standalone: true,
  template: '',
})
export class CommonLayoutComponent implements OnInit {
  get user() {
    return this.authService.user;
  }
  readonly startDrawer$ = new BehaviorSubject<boolean>(false);
  readonly endDrawer$ = new BehaviorSubject<boolean>(false);
  readonly lg$ = new BehaviorSubject<boolean>(false);
  readonly drawerIsOpen$ = combineLatest([
    this.startDrawer$,
    this.endDrawer$,
  ]).pipe(map((values) => values.some((v) => v)));
  protected untilDestroyed = untilDestroyed();

  constructor(
    public readonly layoutService: BaseLayoutService,
    protected readonly localStorageService: LocalStorageService,
    protected readonly authService: AuthService,
    protected readonly breakpoint: BreakpointObserver
  ) {}

  closeDrawer() {
    this.startDrawer$.next(false);
    this.endDrawer$.next(false);
  }

  toggleEndDrawer() {
    this.endDrawer$.next(!this.endDrawer$.value);
  }

  toggleStartDrawer() {
    this.startDrawer$.next(!this.startDrawer$.value);
  }

  ngOnInit(): void {
    this.breakpoint
      .observe(Object.values(BSBreakpoints))
      .pipe(this.untilDestroyed())
      .subscribe((values) => {
        this.lg$.next(values.breakpoints[BSBreakpoints.LG]);
        if (values.breakpoints[BSBreakpoints.LG]) {
          this.closeDrawer();
        }
      });
    this.layoutService.theme$.next(this.localStorageService.getStyle());
    this.layoutService.theme$.pipe(this.untilDestroyed()).subscribe((value) => {
      this.localStorageService.setStyle(value);
      this.layoutService.update();
    });
    /* this.layoutService.asideVisible$
      .pipe(takeUntil(this.destroy$))
      .subscribe((bool) => {
        this.layoutService.asideEnabled = bool;
        this.layoutService.asideFixed = bool;
        this.layoutService.update();
      });*/
    this.layoutService.update();
  }

  onUserMenuItemClick(item: UserMenuItem) {
    if (item.id === 'logout') {
      this.authService.logout();
    }
  }
}
